import Filter from "bad-words";

import { setupCanvas } from "./canvas.js";
import { dayColors } from "./constant.js";
import {
  calculateJawOffset,
  drawArm,
  drawCreatureBody,
  drawCursor,
  drawEars,
  drawEyes,
  drawFly,
  drawJaw,
  drawNose,
  drawRetryText,
  drawTongue,
  drawWaterWave,
  moveFly,
} from "./draw.js";
import { displayElapsedTime, displayFliesCaught } from "./text.js";
import {
  type Fly,
  type LeaderBoard,
  type Point,
  type TonguePhysics,
} from "./types.js";
import {
  calculateDistanceBetweenTwoPoints,
  debounce,
  updateBackgroundColor,
} from "./utils.js";

let remainingTime = 60;
let startTime: number | null = null;
let isTimerStarted = false;
const countdownDuration = 60;
let fliesCaughtCount = 0;
let holdTimer: NodeJS.Timeout | null = null;
const holdDuration = 750;
let waterLevel = 0;
let waterRiseSpeed: number;
const waterFallSpeed = 10;

let isGameOver = false;
let timesUpFontSize = 0;
let showRetryButton = false;

let canvas: HTMLCanvasElement;
let ctx: CanvasRenderingContext2D;
let animationFrameId: number;
let eyesY: number;
let frogRotation = 0;
let frogCenterX: number;
let frogCenterY: number;
let frogRadiusX: number;
let frogRadiusY: number;
let frogCurrentY: number;
let frogTargetY: number;
let cursorX = 0;
let cursorY = 0;
let cursorRadius: number;
let targetCursorRadius: number;
let cursorRotationAngle = 0;
let isMouseDown = false;
let isResetting = false;
let targetTonguePosition: Point = { x: 0, y: 0 };
let tonguePhysics: TonguePhysics;
let isTongueMoving = false;
let flies: Fly[] = [];
const numberOfFlies = 15;

function initializeFlies(numberOfFlies: number) {
  flies = [];
  for (let i = 0; i < numberOfFlies; i++) {
    flies.push({
      position: {
        x: Math.random() * canvas.width,
        y: (Math.random() * canvas.height) / 2,
      },
      direction: { x: 1, y: 1 },
      lastDirectionChangeTime: 0,
      isCaught: false,
    });
  }
}

function initializeCanvas() {
  canvas = document.getElementById("drawingCanvas") as HTMLCanvasElement;

  if (!canvas) {
    throw new Error("Canvas not found");
  }

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  ctx = setupCanvas(canvas);
  cursorRadius = canvas.width / 20;
  targetCursorRadius = cursorRadius;
  eyesY = canvas.height - canvas.height / 20;
  frogCenterX = canvas.width / 2;
  frogCenterY = canvas.height;
  frogRadiusX = canvas.width / 2;
  frogRadiusY = canvas.height / 4;
  frogCurrentY = frogCenterY;
  frogTargetY = frogCenterY;
  tonguePhysics = {
    position: { x: 0, y: 0 },
    target: { x: 0, y: 0 },
    velocity: { x: 0, y: 0 },
    acceleration: { x: 0, y: 0 },
    damping: 0.85,
    retracting: false,
    stiffness: 0.2,
  };
  initializeFlies(numberOfFlies);
  waterRiseSpeed = canvas.height / 100;

  const initialColor = dayColors[0]?.color ?? "#FFFFFF";
  canvas.style.backgroundColor = initialColor;
  canvas.style.cursor = "none";
}

const loadGoogleFont = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Lilita+One&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

function getTouchPos(canvas: HTMLCanvasElement, touchEvent: TouchEvent): Point {
  const rect = canvas.getBoundingClientRect();
  const touch = touchEvent.touches[0];

  if (!touch) {
    return { x: 0, y: 0 };
  }

  return {
    x: touch.clientX - rect.left,
    y: touch.clientY - rect.top,
  };
}

function handleClick(clientX: number, clientY: number) {
  if (!isTimerStarted) {
    startTime = Date.now();
    isTimerStarted = true;
  }

  if (isTongueMoving) {
    return;
  }

  if (isGameOver) {
    isResetting = true;
    holdTimer = setTimeout(() => {
      window.location.reload();
    }, holdDuration);
    return;
  }

  isMouseDown = true;

  const rect = canvas.getBoundingClientRect();

  targetTonguePosition.x = clientX - rect.left;
  targetTonguePosition.y = clientY - rect.top;
  frogTargetY = frogCenterY + canvas.height / 40;
}

const handleMouseUp = () => {
  isMouseDown = false;
  isResetting = false;
  frogTargetY = frogCenterY;

  if (isGameOver) {
    if (holdTimer) {
      clearTimeout(holdTimer);
      holdTimer = null;
    }
  }
};

function handleTouchStart(e: TouchEvent) {
  e.preventDefault(); // Prevent scrolling when touching the canvas
  const touchPos = getTouchPos(canvas, e);
  handleClick(touchPos.x, touchPos.y);
}

// Define the touch end handler
function handleTouchEnd() {
  handleMouseUp();
}

document.addEventListener("DOMContentLoaded", async () => {
  loadGoogleFont();

  initializeCanvas();
  await animate();

  canvas.addEventListener("mousemove", (e: MouseEvent) => {
    const rect = canvas.getBoundingClientRect();
    cursorX = e.clientX - rect.left;
    cursorY = e.clientY - rect.top;
  });

  canvas.addEventListener("mousedown", (e: MouseEvent) => {
    handleClick(e.clientX, e.clientY);
  });

  canvas.addEventListener("mouseup", handleMouseUp);

  canvas.addEventListener(
    "touchmove",
    (e: TouchEvent) => {
      e.preventDefault(); // Prevent scrolling when touching the canvas
      const touchPos = getTouchPos(canvas, e);
      cursorX = touchPos.x;
      cursorY = touchPos.y;
    },
    { passive: false }
  );

  canvas.addEventListener("touchstart", handleTouchStart, { passive: false });
  canvas.addEventListener("touchend", handleTouchEnd, { passive: false });

  window.addEventListener("resize", debounce(handleResize));
});

async function handleResize() {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }

  initializeCanvas();
  await animate();
}

function animateFrog() {
  const angleToCursor = Math.atan2(
    cursorY - frogCenterY,
    cursorX - frogCenterX
  );

  // Convert the desired max rotation to radians
  const maxRotation = 1 * (Math.PI / 180); // Approximately 10 degrees in either direction

  // Adjust the angleToCursor to the frog's perspective
  let desiredRotation = angleToCursor - Math.PI / 2;

  // Normalize the rotation to be within the range of -PI to PI
  if (desiredRotation < -Math.PI) desiredRotation += 2 * Math.PI;
  if (desiredRotation > Math.PI) desiredRotation -= 2 * Math.PI;

  // Since it's backwards, invert the sign of the desired rotation
  desiredRotation = -desiredRotation; // Invert the direction of rotation

  // Clamp the rotation within the maxRotation bounds
  desiredRotation = Math.max(
    -maxRotation,
    Math.min(desiredRotation, maxRotation)
  );

  // Apply a lerp (linear interpolation) to smooth out the rotation
  const rotationSpeed = 0.025; // This controls how quickly the frog rotates towards the cursor
  frogRotation += (desiredRotation - frogRotation) * rotationSpeed;

  // Apply the rotation before drawing the frog and its components
  ctx.save(); // Save the current state of the canvas
  ctx.translate(frogCenterX, frogCenterY); // Move to the frog's center
  ctx.rotate(frogRotation); // Apply the rotation
  ctx.translate(-frogCenterX, -frogCenterY); // Move back

  let closestFlyDistance = Number.MAX_VALUE;
  let closestFly: Fly = {} as Fly;
  const interpolationFactor = 0.05;
  frogCurrentY += (frogTargetY - frogCurrentY) * interpolationFactor;

  // Find the closest fly to the cursor
  flies.forEach((fly) => {
    const distance = calculateDistanceBetweenTwoPoints(fly.position, {
      x: cursorX,
      y: cursorY,
    });
    if (distance < closestFlyDistance) {
      closestFlyDistance = distance;
      closestFly = fly;
    }
  });

  if (!closestFly) {
    throw new Error("No fly found");
  }

  // If there is at least one fly, calculate the jaw offset based on the closest one
  let jawOffset = 0;
  jawOffset = calculateJawOffset({
    cursorY,
    centerY: canvas.height,
    flyDistance: closestFlyDistance,
    maxDistance: canvas.height,
    minDistance: canvas.height / 10,
    threshold: canvas.height / 2,
  });

  drawJaw(ctx, { canvas, jawOffset, centerY: frogCurrentY });

  if (isMouseDown) {
    isTongueMoving = true;
  } else if (
    Math.abs(tonguePhysics.position.y - canvas.width / 2) <
    canvas.height / 2.5
  ) {
    isTongueMoving = false;
  }

  drawTongue(ctx, {
    canvas,
    cursorX,
    cursorY,
    eyesY,
    flyPosition: closestFly.position,
    isMouseDown,
    targetTonguePosition,
    tonguePhysics,
  });

  drawCreatureBody(ctx, {
    centerX: frogCenterX,
    centerY: frogCurrentY,
    radiusX: frogRadiusX,
    radiusY: frogRadiusY,
  });

  drawNose(ctx, {
    height: canvas.width / 100,
    width: canvas.width / 200,
    x: canvas.width / 2,
    xOffset: canvas.width / 20,
    yOffset: frogCurrentY - canvas.height / 5,
  });

  drawEars(ctx, {
    x: canvas.width / 2,
    width: canvas.width / 50,
    height: canvas.width / 50,
    xOffset: canvas.width / 4,
    yOffset: frogCurrentY - canvas.height / 20,
  });

  drawEyes(ctx, {
    canvas,
    cursorX,
    cursorY,
    eyesY: frogCurrentY - canvas.height / 20,
  });

  // left arm
  drawArm(ctx, {
    centerY: frogCurrentY,
    circleRadius: canvas.width / 20,
    control: { x: canvas.width / 10, y: frogCurrentY },
    cursorX,
    cursorY,
    isLeftArm: true,
    start: { x: canvas.width / 10, y: frogCurrentY },
  });

  // right arm
  drawArm(ctx, {
    centerY: frogCurrentY,
    circleRadius: canvas.width / 20,
    control: { x: canvas.width - canvas.width / 10, y: frogCurrentY },
    cursorX,
    cursorY,
    isLeftArm: false,
    start: { x: canvas.width - canvas.width / 10, y: frogCurrentY },
  });

  ctx.restore(); // Restore the canvas state to remove the rotation for other elements
}

function animateFlies() {
  flies.forEach((fly) => {
    const distanceToTongue = calculateDistanceBetweenTwoPoints(
      tonguePhysics.position,
      fly.position
    );
    const distanceToCursor = calculateDistanceBetweenTwoPoints(
      { x: cursorX, y: cursorY },
      fly.position
    );
    const cursorCatchThreshold = cursorRadius;

    // Check if the fly is within the catch threshold of both the tongue and the cursor, and if it's not already caught
    if (
      distanceToTongue < cursorCatchThreshold &&
      distanceToCursor < cursorCatchThreshold &&
      isMouseDown &&
      !fly.isCaught
    ) {
      fly.isCaught = true;
      fliesCaughtCount++;
      // Randomly position the fly around the tongue's tip
      const angle = Math.random() * Math.PI * 2; // Random angle for direction
      const radius = (fliesCaughtCount * canvas.width) / canvas.width / 4; // Distance from the tongue's tip
      fly.tongueOffset = {
        x: Math.cos(angle) * radius,
        y: Math.sin(angle) * radius,
      };
    }

    if (fly.isCaught) {
      if (
        !isMouseDown &&
        tonguePhysics.position.y > frogCenterY - frogRadiusY / 2 - 200
      ) {
        // When the mouse is released and the tongue is retracted, release the fly and reposition it randomly on the canvas
        fly.isCaught = false;
        fly.position.x = Math.random() * canvas.width;
        fly.position.y = (Math.random() * canvas.height) / 2;
      } else if (fly.tongueOffset) {
        // The fly is caught and should be attached to the tongue's position with the offset
        fly.position.x = tonguePhysics.position.x + fly.tongueOffset.x;
        fly.position.y = tonguePhysics.position.y + fly.tongueOffset.y;
      }
      drawFly(ctx, { canvas, fly }); // Draw the fly stuck to the tongue pad
    } else {
      // Handle the moving fly
      moveFly({
        baseAmplitude: 2,
        canvas,
        fly,
        frequency: 0.001,
      });
      drawFly(ctx, { canvas, fly }); // Draw the fly normally
    }
  });
}

function animateCursor() {
  let onTarget = false;

  // Check if the cursor is on target with any of the flies
  for (const fly of flies) {
    const dx = cursorX - fly.position.x;
    const dy = cursorY - fly.position.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    if (distance < cursorRadius) {
      onTarget = true;
      break; // Stop checking if one fly is already on target
    }
  }

  // Determine the target cursor radius and rotation angle
  targetCursorRadius = onTarget ? canvas.width / 30 : canvas.width / 20;
  cursorRotationAngle = onTarget
    ? (cursorRotationAngle + 3) % 360
    : cursorRotationAngle;

  // Smoothly update the cursor's radius using lerp
  const lerpFactor = 0.3;
  cursorRadius += (targetCursorRadius - cursorRadius) * lerpFactor;

  // Draw the cursor with the updated properties
  drawCursor(ctx, {
    cursorX,
    cursorY,
    cursorRadius,
    onTarget,
    rotationDegrees: cursorRotationAngle,
    thickness: canvas.height / 40,
  });
}

function animateTimesUpMessage() {
  if (timesUpFontSize < canvas.width / 10) {
    timesUpFontSize += 5; // Increment font size by 5 each frame
  } else {
    showRetryButton = true; // Show retry button once animation is complete
  }
  ctx.font = `${timesUpFontSize}px 'Lilita One'`;
  ctx.fillStyle = "white";
  ctx.textAlign = "center";
  ctx.fillText(
    "TIME'S UP!",
    canvas.width / 2,
    canvas.height / 2 - canvas.height / 10
  );
}

function updateCursorVisibility() {
  if (isGameOver) {
    canvas.style.cursor = "default"; // Show the cursor when the game is over
  } else {
    canvas.style.cursor = "none"; // Hide the cursor during the game
  }
}

function animateWaterWave() {
  if (isGameOver && isResetting) {
    // Increase water level while holding the mouse
    waterLevel = Math.min(waterLevel + waterRiseSpeed, canvas.height);
  } else {
    // Decrease water level when the mouse is released
    waterLevel = Math.max(waterLevel - waterFallSpeed, 0);
  }

  drawWaterWave(ctx, { canvas, waterLevel });
}

async function checkIfHighScore(
  score: number
): Promise<{ leaderboard: LeaderBoard; isHighScore: boolean }> {
  try {
    const response = await fetch(
      "https://time-flies-server.onrender.com/is-high-score",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ score }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw new Error("Error checking high score");
  }
}

function createLeaderboardTable(leaderboard: LeaderBoard) {
  // Create the table element
  const table = document.createElement("table");
  table.style.width = "100%";
  table.style.borderCollapse = "collapse";

  // Create the header row
  const headerRow = document.createElement("tr");
  ["Rank", "Name", "Score"].forEach((headerText) => {
    const headerCell = document.createElement("th");
    headerCell.textContent = headerText;
    headerCell.style.padding = "8px";
    headerCell.style.borderBottom = "1px solid #ddd";
    headerCell.style.background = "#f3f3f3";
    headerRow.appendChild(headerCell);
  });
  table.appendChild(headerRow);

  // Add the leaderboard entries to the table
  leaderboard.forEach((entry, index) => {
    const row = document.createElement("tr");
    row.style.backgroundColor = index % 2 ? "#f9f9f9" : "#fff"; // Alternate row colors

    // Rank
    const rankCell = document.createElement("td");
    rankCell.textContent = (index + 1).toString();
    rankCell.style.textAlign = "center";
    rankCell.style.padding = "8px";
    row.appendChild(rankCell);

    // Name
    const nameCell = document.createElement("td");
    nameCell.textContent = entry.name;
    nameCell.style.textTransform = "uppercase";
    nameCell.style.padding = "8px";
    if (entry.name === "YOUR NAME HERE") {
      nameCell.style.fontWeight = "bold";
    }
    row.appendChild(nameCell);

    // Score
    const scoreCell = document.createElement("td");
    scoreCell.textContent = entry.score.toString();
    scoreCell.style.textAlign = "center";
    scoreCell.style.padding = "8px";
    row.appendChild(scoreCell);

    table.appendChild(row);
  });

  return table;
}

function createHighScoreModal({
  leaderboard,
  playerScore,
}: {
  leaderboard: LeaderBoard;
  playerScore: number;
}) {
  const yourNameHere = "YOUR NAME HERE";
  let insertIndex = leaderboard.findIndex((entry) => playerScore > entry.score);

  if (insertIndex === -1) {
    if (leaderboard.length < 10) {
      // If the leaderboard is not full, add the score to the end
      leaderboard.push({ name: yourNameHere, score: playerScore });
    } else if (
      playerScore > (leaderboard[leaderboard.length - 1]?.score ?? 0)
    ) {
      // If the leaderboard is full but the player's score is higher than the last score
      leaderboard.pop(); // Remove the last score
      leaderboard.push({ name: yourNameHere, score: playerScore }); // Add the new score to the end
    }
  } else {
    // If the player's score is higher than any of the existing scores
    leaderboard.splice(insertIndex, 0, {
      name: yourNameHere,
      score: playerScore,
    });
    // Ensure the leaderboard does not exceed 10 entries
    if (leaderboard.length > 10) {
      leaderboard.pop();
    }
  }

  // After insertion, sort the leaderboard from highest to lowest
  leaderboard.sort((a, b) => b.score - a.score);

  const modal = document.createElement("div");
  modal.className = "highScoreModal";
  modal.style.cssText =
    "position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); display: flex; align-items: center; justify-content: center;";

  const modalContent = document.createElement("div");
  modalContent.className = "modal-content";
  modalContent.style.cssText =
    "background-color: white; padding: 20px; border-radius: 5px; text-align: center; position: relative;";

  // Close button
  const closeButton = document.createElement("button");
  closeButton.textContent = "X";
  closeButton.style.cssText =
    "font-family: 'Lilita One'; position: absolute; top: 10px; right: 10px; border: none; background: none; cursor: pointer; font-size: 24px;";
  closeButton.onclick = function () {
    document.body.removeChild(modal);
  };
  modalContent.appendChild(closeButton);

  modal.onclick = function (event) {
    if (event.target === modal) {
      document.body.removeChild(modal);
    }
  };
  modalContent.onclick = function (event) {
    event.stopPropagation();
  };

  const title = document.createElement("h1");
  title.textContent = "NEW HIGH SCORE!";
  modalContent.appendChild(title);

  const scoreDisplay = document.createElement("h2");
  scoreDisplay.textContent = `YOU ATE ${fliesCaughtCount} ${
    fliesCaughtCount === 1 ? "FLY" : "FLIES"
  }`;
  modalContent.appendChild(scoreDisplay);

  const form = document.createElement("form");
  form.id = "highScoreForm";
  form.addEventListener("submit", submitHighScore);

  const input = document.createElement("input");
  input.type = "text";
  input.id = "playerName";
  input.name = "playerName";
  input.placeholder = "Enter your name";
  input.required = true;
  input.style.cssText = "margin-bottom: 10px;";

  const submitButton = document.createElement("button");
  submitButton.type = "submit";
  submitButton.id = "submit";
  submitButton.textContent = "SUBMIT";
  submitButton.style.cssText = "cursor: pointer;";

  form.appendChild(input);
  const errorParagraph = document.createElement("p");
  errorParagraph.id = "error-message";
  errorParagraph.style.display = "none";
  errorParagraph.style.color = "red";
  modalContent.appendChild(errorParagraph);
  form.appendChild(submitButton);

  const table = createLeaderboardTable(leaderboard);

  modalContent.appendChild(table);
  modalContent.appendChild(form);

  modal.appendChild(modalContent);
  document.body.appendChild(modal);
}

async function checkAndShowHighScoreModal(fliesCaughtCount: number) {
  try {
    const { isHighScore, leaderboard } = await checkIfHighScore(
      fliesCaughtCount
    );
    if (isHighScore) {
      createHighScoreModal({
        leaderboard,
        playerScore: fliesCaughtCount,
      });
      const modal = document.getElementById("modal");
      if (modal) {
        // Check if the modal exists
        modal.style.display = "block";
      }
    }
  } catch (error) {
    console.error("Error checking high score:", error);
  }
}

async function submitHighScore(event: SubmitEvent) {
  event.preventDefault();

  // Use a type guard to check if the element is an HTMLInputElement
  const playerNameInput = document.getElementById("playerName");
  if (playerNameInput && playerNameInput instanceof HTMLInputElement) {
    const playerName = playerNameInput.value;

    const filter = new Filter();

    if (filter.isProfane(playerName)) {
      const errorMessage = document.getElementById("error-message");
      if (errorMessage) {
        errorMessage.textContent = "Please enter an appropriate name";
        errorMessage.style.display = "block";
      }

      return;
    } else {
      const errorMessage = document.getElementById("error-message");
      if (errorMessage) {
        errorMessage.style.display = "none";
      }
    }

    try {
      const response = await fetch(
        "https://time-flies-server.onrender.com/update-leaderboard",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: playerName
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" "),
            score: fliesCaughtCount,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting score:", error);
    }

    const modal = document.getElementsByClassName("highScoreModal")[0];
    if (modal) {
      modal.remove();
    }
  } else {
    console.error("Player name input element not found or invalid type");
  }
}

async function animate() {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  displayElapsedTime(ctx, { canvas, remainingTime });
  displayFliesCaught(ctx, { canvas, count: fliesCaughtCount });

  if (isTimerStarted && startTime != null) {
    const currentTime = Date.now();
    const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
    remainingTime = Math.max(0, countdownDuration - elapsedSeconds); // Ensure time doesn't go below 0

    updateBackgroundColor({ canvas, elapsedSeconds });
  }

  if (remainingTime <= 0 && !isGameOver) {
    isGameOver = true;
    updateCursorVisibility();
    try {
      checkAndShowHighScoreModal(fliesCaughtCount);
    } catch (error) {
      console.log(error);
    }
  }

  if (!isGameOver) {
    animateCursor();
  }

  animateFrog();
  animateFlies();
  animateWaterWave();

  if (isGameOver) {
    animateTimesUpMessage();
  }

  if (showRetryButton) {
    drawRetryText(ctx, { canvas });
  }

  animationFrameId = requestAnimationFrame(animate);
}
