import { dayColors } from "./constant";
import { type Point } from "./types";

export function calculateDistanceBetweenTwoPoints(
  pointA: Point,
  pointB: Point
) {
  const dx = pointA.x - pointB.x;
  const dy = pointA.y - pointB.y;

  return Math.sqrt(dx * dx + dy * dy);
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1] ?? "0", 16),
        g: parseInt(result[2] ?? "0", 16),
        b: parseInt(result[3] ?? "0", 16),
      }
    : { r: 0, g: 0, b: 0 };
}

function rgbToHex(r: number, g: number, b: number): string {
  return (
    "#" +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")
  );
}

export function interpolateColor(
  color1: string,
  color2: string,
  ratio: number
): string {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const r = Math.round(rgb1.r + ratio * (rgb2.r - rgb1.r));
  const g = Math.round(rgb1.g + ratio * (rgb2.g - rgb1.g));
  const b = Math.round(rgb1.b + ratio * (rgb2.b - rgb1.b));

  return rgbToHex(r, g, b);
}

export function updateBackgroundColor({
  canvas,
  elapsedSeconds,
}: {
  canvas: HTMLCanvasElement;
  elapsedSeconds: number;
}) {
  for (let i = 0; i < dayColors.length - 1; i++) {
    const currentColorTime = dayColors[i]?.time ?? 0;
    const nextColorTime = dayColors[i + 1]?.time ?? 0;

    if (elapsedSeconds >= currentColorTime && elapsedSeconds < nextColorTime) {
      const ratio =
        (elapsedSeconds - currentColorTime) /
        (nextColorTime - currentColorTime);
      const currentColor = dayColors[i]?.color ?? "#FFFFFF";
      const nextColor = dayColors[i + 1]?.color ?? "#FFFFFF";

      const interpolatedColor = interpolateColor(
        currentColor,
        nextColor,
        ratio
      );
      canvas.style.backgroundColor = interpolatedColor;
      break;
    }
  }
}

export function debounce(func: () => void, timeout = 300) {
  let timer: number;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
