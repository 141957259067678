function displayLabelAndValue(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    label,
    value,
    posX,
    posY,
  }: {
    canvas: HTMLCanvasElement;
    label: string;
    value: string;
    posX: number;
    posY: number;
  }
) {
  const fontSize = canvas.width / 20;
  ctx.font = `${fontSize}px 'Lilita One'`;
  ctx.fillStyle = "white";
  ctx.textAlign = "center";
  ctx.fillText(label, posX, posY);

  ctx.font = `${canvas.width / 10}px 'Lilita One'`;
  ctx.fillText(value, posX, posY + fontSize * 1.75);
}

export function displayElapsedTime(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    remainingTime,
  }: {
    canvas: HTMLCanvasElement;
    remainingTime: number;
  }
) {
  const timeLabelPosX = canvas.width / 2;
  const timeLabelPosY = canvas.height / 8;
  displayLabelAndValue(ctx, {
    canvas,
    label: "TIME",
    value: `${remainingTime}`,
    posX: timeLabelPosX - canvas.width / 10,
    posY: timeLabelPosY,
  });
}

export function displayFliesCaught(
  ctx: CanvasRenderingContext2D,
  { canvas, count }: { canvas: HTMLCanvasElement; count: number }
) {
  const fliesLabelPosX = canvas.width / 2; // Center of the canvas width
  const fliesLabelPosY = canvas.height / 8; // Position for the FLIES label
  displayLabelAndValue(ctx, {
    canvas,
    label: "FLIES",
    value: `${count}`,
    posX: fliesLabelPosX + canvas.width / 10, // Offset to the right by 100 pixels from center
    posY: fliesLabelPosY,
  });
}
