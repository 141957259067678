import { type Fly, type Point, type TonguePhysics } from "./types";

const green = "#526f52";
const darkGreen = "#2f422f";

export function drawCursor(
  ctx: CanvasRenderingContext2D,
  {
    cursorX,
    cursorY,
    cursorRadius,
    thickness,
    onTarget,
    rotationDegrees,
  }: {
    cursorX: number;
    cursorY: number;
    cursorRadius: number;
    onTarget: boolean;
    thickness: number;
    rotationDegrees: number;
  }
) {
  const circumference = 2 * Math.PI * cursorRadius;

  const dashLength = 5; // Initial desired length of dash
  const spaceLength = 16; // Initial desired length of space

  const totalDashSpaceLength = dashLength + spaceLength;
  const numberOfDashes = Math.floor(circumference / totalDashSpaceLength);
  const adjustedTotalLength = circumference / numberOfDashes;

  const adjustedDashLength =
    (dashLength / totalDashSpaceLength) * adjustedTotalLength;
  const adjustedSpaceLength = adjustedTotalLength - adjustedDashLength;

  const cursorColor = "rgba(255, 255, 255, 1)";

  // Save the current context state
  ctx.save();

  // Set the style for the cursor
  ctx.strokeStyle = cursorColor;
  ctx.lineWidth = thickness;
  ctx.setLineDash([adjustedDashLength, adjustedSpaceLength]);

  // Rotate the canvas around the cursor if overlapping
  if (onTarget) {
    ctx.translate(cursorX, cursorY);
    ctx.rotate((rotationDegrees * Math.PI) / 180);
    ctx.translate(-cursorX, -cursorY);
  }

  // Draw the cursor
  ctx.beginPath();
  ctx.arc(cursorX, cursorY, cursorRadius, 0, Math.PI * 2);
  ctx.stroke();

  // Restore the original context state
  ctx.restore();
  ctx.setLineDash([]);
}

export function drawArm(
  ctx: CanvasRenderingContext2D,
  {
    centerY,
    circleRadius,
    control,
    cursorX,
    cursorY,
    isLeftArm,
    start,
  }: {
    centerY: number;
    circleRadius: number;
    control: Point;
    cursorX: number;
    cursorY: number;
    isLeftArm: boolean;
    start: Point;
  }
) {
  // Calculate the end point based on the cursor position and circleRadius
  const angleToCursor = Math.atan2(cursorY - start.y, cursorX - start.x);
  let end = {
    x: cursorX - Math.cos(angleToCursor) * (circleRadius * 2),
    y: cursorY - Math.sin(angleToCursor) * (circleRadius * 2),
  };

  // Constrain the arm to its respective side with a gap around the center
  const midX = ctx.canvas.width / 2;
  const gapRadius = circleRadius * 5.5; // Adjust the gap as needed

  if (isLeftArm) {
    end.x = Math.min(end.x, midX - gapRadius);
  } else {
    end.x = Math.max(end.x, midX + gapRadius);
  }

  // Restrict the y position of the arm so it does not go above the top half of the canvas
  const maxY = ctx.canvas.height / 1.5;
  end.y = Math.max(end.y, maxY);

  // Function to draw a single Bezier curve
  function drawCurve(
    startX: number,
    startY: number,
    control1Y: number,
    control2Y: number,
    endX: number,
    endY: number
  ) {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.bezierCurveTo(
      startX,
      startY + (endY - startY) * control1Y,
      startX,
      startY + (endY - startY) * control2Y,
      endX,
      endY
    );
    ctx.stroke();
  }

  // Set line width to twice the radius to match the circle's diameter
  ctx.strokeStyle = green;
  ctx.fillStyle = green;
  ctx.lineWidth = circleRadius * 2;

  // Offset for the additional curves
  let offsetX = 50; // Adjust this value for wider or narrower base

  // Draw the original curve and additional offset curves
  drawCurve(start.x, centerY, 0.2, 0.4, end.x, end.y);
  drawCurve(start.x - offsetX, start.y, 0.2, 0.4, end.x, end.y);
  drawCurve(start.x + offsetX, start.y, 0.2, 0.4, end.x, end.y);

  // Draw circle at the end point
  ctx.beginPath();
  ctx.arc(end.x, end.y, circleRadius, 0, Math.PI * 2);
  ctx.fill();
}

function drawEquilateralTriangle(
  ctx: CanvasRenderingContext2D,
  {
    x,
    y,
    circleRadius,
    size,
    angle,
    color,
  }: {
    x: number;
    y: number;
    circleRadius: number;
    size: number;
    angle: number;
    color: string;
  }
) {
  const height = (size * Math.sqrt(3)) / 2;

  ctx.save();
  ctx.translate(x, y);
  ctx.rotate(angle);

  // Position the base of the triangle at the circle's edge
  ctx.beginPath();
  ctx.moveTo(0, -circleRadius - height);
  ctx.lineTo(-size / 2, -circleRadius);
  ctx.lineTo(size / 2, -circleRadius);
  ctx.closePath();

  ctx.fillStyle = color;
  ctx.fill();
  ctx.restore();
}

export function calculateJawOffset({
  cursorY,
  centerY,
  maxDistance,
  minDistance,
  threshold,
  flyDistance,
}: {
  cursorY: number;
  centerY: number;
  maxDistance: number;
  minDistance: number;
  threshold: number;
  flyDistance: number;
}) {
  // The closer the cursor is to the fly, the more the jaw should open
  const jawOpenFactor = Math.max(0, 1 - flyDistance / threshold);

  const distanceFromBottom = Math.abs(cursorY - centerY);
  let offset: number;

  if (distanceFromBottom > maxDistance) {
    offset = 0; // Jaw is not revealed
  } else {
    // Interpolate the jaw position based on the cursor's distance from bottom
    offset =
      -100 *
      (1 - (distanceFromBottom - minDistance) / (maxDistance - minDistance));
    // Adjust the offset based on the proximity to the fly
    offset *= jawOpenFactor;
  }

  return Math.max(offset, -100); // Ensure offset is not less than -100
}

export function drawJaw(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    centerY,
    jawOffset,
  }: { canvas: HTMLCanvasElement; centerY: number; jawOffset: number }
) {
  const centerX = canvas.width / 2;
  // const centerY = canvas.height; // The Y position of the body's bottom
  const radiusX = canvas.width / 2.25;
  const radiusY = canvas.height / 4;

  // Draw the jaw circle
  ctx.beginPath();
  ctx.ellipse(
    centerX,
    centerY + jawOffset,
    radiusX,
    radiusY,
    0,
    0,
    Math.PI * 2
  );
  ctx.fillStyle = darkGreen;
  ctx.fill();
}

export function drawCreatureBody(
  ctx: CanvasRenderingContext2D,
  {
    centerX,
    centerY,
    radiusX,
    radiusY,
  }: {
    centerX: number;
    centerY: number;
    radiusX: number;
    radiusY: number;
  }
) {
  ctx.beginPath();
  ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, Math.PI * 2);
  ctx.fillStyle = green;
  ctx.fill();
}

export function drawEars(
  ctx: CanvasRenderingContext2D,
  {
    height,
    width,
    x,
    xOffset,
    yOffset,
  }: {
    x: number;
    width: number;
    height: number;
    xOffset: number;
    yOffset: number;
  }
) {
  // Drawing left ear part
  ctx.save();
  ctx.beginPath();
  ctx.ellipse(
    x - xOffset,
    yOffset,
    width,
    height,
    Math.PI / 12, // Slight rotation inward
    0,
    Math.PI * 2
  );
  ctx.fillStyle = darkGreen;
  ctx.fill();
  ctx.restore();

  // Drawing right ear part
  ctx.save();
  ctx.beginPath();
  ctx.ellipse(
    x + xOffset,
    yOffset,
    width,
    height,
    -Math.PI / 12, // Slight rotation inward
    0,
    Math.PI * 2
  );
  ctx.fillStyle = darkGreen;
  ctx.fill();
  ctx.restore();
}

export function drawNose(
  ctx: CanvasRenderingContext2D,
  {
    x,
    width,
    height,
    xOffset,
    yOffset,
  }: {
    x: number;
    width: number;
    height: number;
    xOffset: number;
    yOffset: number;
  }
) {
  // Drawing left nose part
  ctx.save();
  ctx.beginPath();
  ctx.ellipse(
    x - xOffset,
    yOffset,
    width,
    height,
    Math.PI / 12, // Slight rotation inward
    0,
    Math.PI * 2
  );
  ctx.fillStyle = darkGreen;
  ctx.fill();
  ctx.restore();

  // Drawing right nose part
  ctx.save();
  ctx.beginPath();
  ctx.ellipse(
    x + xOffset,
    yOffset,
    width,
    height,
    -Math.PI / 12, // Slight rotation inward
    0,
    Math.PI * 2
  );
  ctx.fillStyle = darkGreen;
  ctx.fill();
  ctx.restore();
}

export function drawEyes(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    cursorX,
    cursorY,
    eyesY,
  }: {
    canvas: HTMLCanvasElement;
    cursorX: number;
    cursorY: number;
    eyesY: number;
  }
) {
  const eyeRadius = canvas.width / 20;
  const eyesX = canvas.width / 2;
  const eyeSeparation = canvas.width / 10;
  const leftEyeX = eyesX - eyeSeparation; // Position of the left eye
  const rightEyeX = eyesX + eyeSeparation; // Position of the right eye
  const leftEyeY = eyesY;
  const rightEyeY = eyesY;

  // Function to calculate the eyelid's vertical offset
  const calculateEyelidOffset = (distanceToEye: number) => {
    const maxOpenDistance = 1000;
    const fullyClosedDistance = 50;
    if (distanceToEye >= maxOpenDistance) {
      return eyeRadius; // Eyelid is fully open
    } else if (distanceToEye <= fullyClosedDistance) {
      return 0; // Eyelid is fully closed
    } else {
      // Interpolate eyelid position
      return (
        (eyeRadius * (distanceToEye - fullyClosedDistance)) /
        (maxOpenDistance - fullyClosedDistance)
      );
    }
  };

  // Calculate distance from the cursor to each eye
  const distanceToLeftEye = calculateDistance(
    leftEyeX,
    leftEyeY,
    cursorX,
    cursorY
  );
  const distanceToRightEye = calculateDistance(
    rightEyeX,
    rightEyeY,
    cursorX,
    cursorY
  );

  // Calculate the eyelid offsets
  const leftEyelidOffset = calculateEyelidOffset(distanceToLeftEye);
  const rightEyelidOffset = calculateEyelidOffset(distanceToRightEye);

  // Draw left eye white
  ctx.beginPath();
  ctx.arc(leftEyeX, leftEyeY, eyeRadius, 0, Math.PI * 2);
  ctx.fillStyle = "white";
  ctx.fill();

  // Draw right eye white
  ctx.beginPath();
  ctx.arc(rightEyeX, rightEyeY, eyeRadius, 0, Math.PI * 2);
  ctx.fillStyle = "white";
  ctx.fill();

  drawPupils(ctx, {
    cursorX,
    cursorY,
    eyesX,
    eyeSeparation,
    leftEyeY,
    rightEyeY,
    eyeRadius,
  });

  // Draw left eyelid
  ctx.beginPath();
  ctx.ellipse(
    leftEyeX,
    leftEyeY + eyeRadius / 2 + leftEyelidOffset,
    eyeRadius * 2,
    eyeRadius,
    0,
    0,
    Math.PI * 2,
    false
  );
  ctx.fillStyle = green;
  ctx.fill();

  // Draw right eyelid
  ctx.beginPath();
  ctx.ellipse(
    rightEyeX,
    rightEyeY + eyeRadius / 2 + rightEyelidOffset,
    eyeRadius * 2,
    eyeRadius,
    0,
    0,
    Math.PI * 2,
    false
  );
  ctx.fillStyle = green;
  ctx.fill();
}

// Helper function to calculate distance between two points
function calculateDistance(
  pointX: number,
  pointY: number,
  cursorX: number,
  cursorY: number
) {
  const dx = cursorX - pointX;
  const dy = cursorY - pointY;
  return Math.sqrt(dx * dx + dy * dy);
}

export function drawPupils(
  ctx: CanvasRenderingContext2D,
  {
    cursorX,
    cursorY,
    eyesX,
    eyeSeparation,
    leftEyeY,
    rightEyeY,
    eyeRadius,
  }: {
    cursorX: number;
    cursorY: number;
    eyesX: number;
    eyeSeparation: number;
    leftEyeY: number;
    rightEyeY: number;
    eyeRadius: number;
  }
) {
  // Positions for the left and right eyes
  const leftEyeX = eyesX - eyeSeparation;
  const rightEyeX = eyesX + eyeSeparation;
  const pupilRadius = eyeRadius / 1.25; // Radius of the pupil

  // Calculate pupil positions
  const leftPupil = calculatePupilPosition(
    leftEyeX,
    leftEyeY,
    eyeRadius,
    cursorX,
    cursorY,
    pupilRadius
  );
  const rightPupil = calculatePupilPosition(
    rightEyeX,
    rightEyeY,
    eyeRadius,
    cursorX,
    cursorY,
    pupilRadius
  );

  // Draw left pupil
  ctx.beginPath();
  ctx.ellipse(
    leftPupil.x,
    leftPupil.y,
    pupilRadius / 2,
    pupilRadius,
    0,
    0,
    Math.PI * 2
  );

  ctx.fillStyle = "black";
  ctx.fill();

  // Draw right pupil
  ctx.beginPath();
  ctx.ellipse(
    rightPupil.x,
    rightPupil.y,
    pupilRadius / 2,
    pupilRadius,
    0,
    0,
    Math.PI * 2
  );
  ctx.fillStyle = "black";
  ctx.fill();
}

function calculatePupilPosition(
  eyeX: number,
  eyeY: number,
  eyeRadius: number,
  cursorX: number,
  cursorY: number,
  pupilRadius: number
) {
  const dx = cursorX - eyeX;
  const dy = cursorY - eyeY;
  const distance = Math.sqrt(dx * dx + dy * dy);

  if (distance < eyeRadius - pupilRadius) {
    // Cursor is within the eye's range
    return { x: cursorX, y: cursorY };
  } else {
    // Cursor is outside the eye's range, clamp the pupil's movement
    const angle = Math.atan2(dy, dx);
    return {
      x: eyeX + (eyeRadius - pupilRadius) * Math.cos(angle),
      y: eyeY + (eyeRadius - pupilRadius) * Math.sin(angle),
    };
  }
}

export function drawMouth(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement
) {
  const mouthWidth = 80; // Adjust the width of the mouth as needed
  const mouthHeight = 10; // Adjust the height/curve of the mouth as needed
  const centerX = canvas.width / 2; // The mouth will be centered horizontally
  const centerY = canvas.height - canvas.height / 4 + 40; // Position the mouth below the eyes

  ctx.beginPath();
  ctx.moveTo(centerX - mouthWidth / 2, centerY); // Start from the left end of the mouth
  // Draw a quadratic curve for the mouth
  ctx.quadraticCurveTo(
    centerX, // Control point at the center of the mouth horizontally
    centerY + mouthHeight, // Adjust the control point vertically to get the desired curve
    centerX + mouthWidth / 2, // End at the right end of the mouth
    centerY
  );
  ctx.stroke(); // Stroke the mouth
}

function lerp(start: number, end: number, amt: number) {
  return (1 - amt) * start + amt * end;
}

export function drawTongue(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    cursorX,
    cursorY,
    eyesY,
    flyPosition,
    isMouseDown,
    targetTonguePosition,
    tonguePhysics,
  }: {
    canvas: HTMLCanvasElement;
    cursorX: number;
    cursorY: number;
    eyesY: number;
    flyPosition: Point;
    isMouseDown: boolean;
    targetTonguePosition: Point;
    tonguePhysics: TonguePhysics;
  }
) {
  const mouthCenterX = canvas.width / 2;
  const mouthCenterY = eyesY - canvas.width / 30;
  const dxFly = tonguePhysics.target.x - tonguePhysics.position.x;
  const dyFly = tonguePhysics.target.y - tonguePhysics.position.y;

  if (Math.abs(tonguePhysics.position.y - mouthCenterY) < 100) {
    tonguePhysics.retracting = false;
  }

  if (isMouseDown) {
    // When the mouse is pressed, target the cursor's position
    tonguePhysics.target.x = targetTonguePosition.x;
    tonguePhysics.target.y = targetTonguePosition.y;
  } else {
    // When the mouse is released and the tongue isn't retracting yet, follow the cursor
    const tongueRadius = canvas.width / 15;
    const spaceRadius = tongueRadius * 2;
    const tonguePosition = calculateCirclePosition(
      mouthCenterX,
      mouthCenterY,
      spaceRadius,
      cursorX,
      cursorY,
      tongueRadius
    );

    if (!tonguePhysics.retracting && !isMouseDown) {
      const lerpAmount = 0.05; // Adjust this value to control the smoothing

      tonguePhysics.retracting = false;
      // Calculate distance to the fly
      const distanceToFly = calculateDistance(
        flyPosition.x,
        flyPosition.y,
        cursorX,
        cursorY
      );
      const maxExtendDistance = canvas.width / 30; // Maximum distance for the tongue to extend
      const tongueExtendFactor = Math.max(
        0,
        1 - distanceToFly / maxExtendDistance
      );

      // Update tongue target to extend slightly towards the fly
      tonguePhysics.target.x = lerp(
        tonguePhysics.target.x,
        tonguePosition.x +
          (flyPosition.x - tonguePosition.x) * tongueExtendFactor,
        lerpAmount
      );
      tonguePhysics.target.y = lerp(
        tonguePhysics.target.y,
        tonguePosition.y +
          (flyPosition.y - tonguePosition.y) * tongueExtendFactor,
        lerpAmount
      );
    } else {
      tonguePhysics.retracting = true;
      tonguePhysics.target.x = mouthCenterX;
      tonguePhysics.target.y = mouthCenterY + tongueRadius;
    }

    // Start retracting after reaching the cursor's position
  }

  // Apply spring physics in all cases
  const stiffness = 0.1;
  const damping = 0.7;

  tonguePhysics.acceleration.x =
    (tonguePhysics.target.x - tonguePhysics.position.x) * stiffness;
  tonguePhysics.acceleration.y =
    (tonguePhysics.target.y - tonguePhysics.position.y) * stiffness;

  tonguePhysics.velocity.x += tonguePhysics.acceleration.x;
  tonguePhysics.velocity.y += tonguePhysics.acceleration.y;

  tonguePhysics.velocity.x *= damping;
  tonguePhysics.velocity.y *= damping;

  tonguePhysics.position.x += tonguePhysics.velocity.x;
  tonguePhysics.position.y += tonguePhysics.velocity.y;

  // Calculate the direction and perpendicular vectors for the sine wave
  const dx = tonguePhysics.position.x - mouthCenterX;
  const dy = tonguePhysics.position.y - mouthCenterY;
  const length = Math.sqrt(dx * dx + dy * dy);
  const dirX = dx / length;
  const dirY = dy / length;
  const perpX = -dirY;
  const perpY = dirX;

  // Parameters for the sine wave
  const frequency = 2; // Number of waves along the tongue's length
  const amplitude = 10; // Amplitude of the sine wave

  // Draw each segment of the tongue
  for (let i = 0; i <= length; i += 10) {
    const segmentRatio = i / length;
    const waveOffset =
      Math.sin(segmentRatio * frequency * Math.PI * 2) * amplitude;
    const segmentX = mouthCenterX + dirX * i + perpX * waveOffset;
    const segmentY = mouthCenterY + dirY * i + perpY * waveOffset;

    if (i === 0) {
      ctx.beginPath();
      ctx.moveTo(segmentX, segmentY);
    } else {
      ctx.lineTo(segmentX, segmentY);
    }
  }

  // Finish the tongue path
  ctx.lineTo(tonguePhysics.position.x, tonguePhysics.position.y);
  ctx.lineWidth = canvas.width / 20; // Set the tongue thickness
  ctx.strokeStyle = "pink";
  ctx.stroke();

  // Interpolating the pad size
  const maxPadWidth = canvas.width / 8;
  const minPadWidth = canvas.width / 25;
  const maxPadHeight = canvas.width / 20;
  const minPadHeight = canvas.width / 30;
  const maxDistance = canvas.width / 2;
  const padWidth =
    minPadWidth +
    (maxPadWidth - minPadWidth) * (1 - Math.min(length / maxDistance, 1));
  const padHeight =
    minPadHeight +
    (maxPadHeight - minPadHeight) * (1 - Math.min(length / maxDistance, 1));

  // Draw the tongue pad at the end of the tongue
  ctx.beginPath();
  ctx.ellipse(
    tonguePhysics.position.x,
    tonguePhysics.position.y,
    padWidth,
    padHeight,
    0,
    0,
    Math.PI * 2,
    false
  );
  ctx.fillStyle = "pink";
  ctx.fill();
}

function calculateCirclePosition(
  centerX: number,
  centerY: number,
  spaceRadius: number,
  cursorX: number,
  cursorY: number,
  circleRadius: number
) {
  const dx = cursorX - centerX;
  const dy = cursorY - centerY;
  const distance = Math.sqrt(dx * dx + dy * dy);
  const maxOffset = spaceRadius - circleRadius;
  if (distance < maxOffset) {
    return { x: cursorX, y: cursorY };
  } else {
    const angle = Math.atan2(dy, dx);
    return {
      x: centerX + maxOffset * Math.cos(angle),
      y: centerY + maxOffset * Math.sin(angle),
    };
  }
}

export function moveFly({
  canvas,
  baseAmplitude,
  fly,
  frequency,
}: {
  canvas: HTMLCanvasElement;
  baseAmplitude: number; // Base amplitude for movement
  fly: Fly;
  frequency: number;
}) {
  // Randomly change direction more frequently for jittery movement
  if (Math.random() < 0.1) {
    // Around a 10% chance each frame
    fly.direction.x = (Math.random() - 0.5) * 2; // Random direction between -1 and 1
    fly.direction.y = (Math.random() - 0.5) * 2; // Random direction between -1 and 1
  }

  // Randomly apply a sudden turn
  if (Math.random() < 0.02) {
    // Around a 2% chance each frame
    fly.direction.x *= 3; // Double the direction change for a sudden turn
    fly.direction.y *= 2; // Double the direction change for a sudden turn
  }

  // Vary the amplitude randomly for each movement
  const amplitude = baseAmplitude * (0.5 + Math.random());

  // Update position with jittery movement
  fly.position.x += fly.direction.x * amplitude;
  fly.position.y += fly.direction.y * amplitude;

  const jitterIntensity = 10.5;

  fly.position.x += (Math.random() - 0.5) * jitterIntensity;
  fly.position.y += (Math.random() - 0.5) * jitterIntensity;

  const maxY = canvas.height * (2 / 3); // Set the maximum Y to two-thirds of the canvas height
  fly.position.x = Math.max(0, Math.min(fly.position.x, canvas.width));
  fly.position.y = Math.max(0, Math.min(fly.position.y, maxY));
}

export function drawFly(
  ctx: CanvasRenderingContext2D,
  { canvas, fly }: { canvas: HTMLCanvasElement; fly: Fly }
) {
  ctx.beginPath();
  ctx.arc(fly.position.x, fly.position.y, canvas.width / 160, 0, Math.PI * 2);
  ctx.fillStyle = "black";
  ctx.fill();
}

export function drawWaterWave(
  ctx: CanvasRenderingContext2D,
  {
    canvas,
    waterLevel,
  }: {
    canvas: HTMLCanvasElement;
    waterLevel: number;
  }
) {
  const waveHeight = canvas.height / 10; // Height of the wave area
  const startY = canvas.height - waveHeight - waterLevel;

  const waveAmplitude = 10; // Height of the wave peaks
  const waveFrequency = 0.01; // Frequency of the waves
  const waveSpeed = 0.002; // Speed of wave movement
  const time = Date.now() * waveSpeed;

  ctx.fillStyle = "rgba(0, 123, 255, 0.3)"; // Transparent blue color
  ctx.beginPath();
  ctx.moveTo(0, startY);

  // Draw the sine wave
  for (let x = 0; x <= canvas.width; x++) {
    const y = startY + Math.sin(x * waveFrequency + time) * waveAmplitude;
    ctx.lineTo(x, y);
  }

  // Close the path to fill the area
  ctx.lineTo(canvas.width, canvas.height);
  ctx.lineTo(0, canvas.height);
  ctx.closePath();
  ctx.fill();
}
export function drawRetryText(
  ctx: CanvasRenderingContext2D,
  { canvas }: { canvas: HTMLCanvasElement }
) {
  // Draw the text
  const buttonText = "Click and hold anywhere to play again";
  const fontSize = canvas.width / 20; // Adjust the font size as needed
  ctx.font = `${fontSize}px 'Lilita One'`;
  ctx.fillStyle = "white";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle"; // Align text vertically in the middle

  // Calculate the center of the button for the text
  const textX = canvas.width / 2;
  const textY = canvas.height / 2 + fontSize;

  ctx.fillText(buttonText, textX, textY);
}
