export function setupCanvas(
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Could not get canvas context");
  }

  return ctx;
}
